import React, { lazy, Suspense } from 'react';

const LazyHome = React.lazy(() => import('./Home'));

const Home = props => (
  <Suspense fallback={null}>
    <LazyHome {...props} />
  </Suspense>
);

export default Home;
