import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../services/api';

const initialState = {
    data: null,
    id: null,
    isChange: false
}

const questionInfoSlice = createSlice({
    name: "questionInfo",
    initialState,
    reducers: {
        setQuestion(state, action) {
            return { ...initialState, id: action.payload.id, data: action.payload.data };
        },
        updateQuestion(state, action) {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }, isChange: true
            };
        },
        setIsChange(state, action) {
            return { ...state, isChange: action.payload };
        }
    },
});
export const { setQuestion, updateQuestion, setIsChange } = questionInfoSlice.actions;
export default questionInfoSlice.reducer

