import { configureStore } from '@reduxjs/toolkit'
import questionInfo from './reducers/questionInfo'


const store = configureStore({
    reducer: {
        questionInfo: questionInfo
    }
})

export default store